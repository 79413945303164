import React from 'react';
import './Components.css';
// import IP from './IP'

export default class Top extends React.Component{
  render() {
    return (
      <div className="Top">       
{/*        <IP /> */}
      </div>
    );
  }
}
